import { useState, useEffect, StrictMode } from "react";
import cs from "../styles/model.module.css";
import axios from "axios";
import ScreenHeader, { ScreenFooter } from "../components/foot&head";

export default function ModelScreen({ setPage, events }) {
  const [data, setData] = useState({});
  const [photos, setPhotos] = useState([]);
  const [selected, setselected] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    axios
      .get(`https://api.avidhilda.com/api/models/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data.profile);
          setPhotos(response.data.data.gallery);
        }
      })
      .catch(() => {});
  }, []);

  const _eachDetails = (v, t, u) => {
    if (v === null || v === undefined || v === "") return "";
    return (
      <div className={cs.bodycaracteristic}>
        {v} {u}
        <br />
        {t}
      </div>
    );
  };

  return (
    <StrictMode>
      <div className={cs.modelpage}>
        <div className={cs.nameandcaractert}>
          <div className={cs.name}>
            {data?.name?.split(",")[0]?.toUpperCase()}
          </div>
          {data?.professional == "Yes" && (
            <div style={{ color: "white" }}>Professional </div>
          )}
          {data?.professional == "No" && (
            <div style={{ color: "white" }}>Beginner</div>
          )}
          <div className={cs.containermodels}>
            {_eachDetails(data?.height, "Height", "cm")}
            {_eachDetails(data?.chest, "Chest", "cm")}
            {_eachDetails(data?.waist, "Waist", "cm")}
            {_eachDetails(data?.hips, "Hips", "cm")}
            {_eachDetails(data?.shoesize, "Shoe Size", "")}
            {_eachDetails(data?.eyecolor, "Eye Color", "")}
            {_eachDetails(data?.haircolor, "hair Color", "")}
          </div>
        </div>
        <div style={{ height: 1, background: "white", marginBottom: 50 }} />
        <section className={cs.imgmodels}>
          <div className={cs.rowone}>
            {photos?.map((it, k) => (
              <div
                key={k}
                className={cs.imgandtitle}
                onClick={() => setselected(it?.image)}
              >
                <img src={it?.image} alt="Each Piecture" />
              </div>
            ))}
          </div>
        </section>
      </div>
      <ScreenFooter setPage={setPage} />
      <ScreenHeader setPage={setPage} />
      {selected !== "" && (
        <div className={cs.popup1} onClick={() => setselected("")}>
          <img className={cs.popupBody} src={selected} alt="MediaPic" />
        </div>
      )}
    </StrictMode>
  );
}
